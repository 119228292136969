<template>
        <v-row>
            <!-- <v-col cols="12">
                <Wallet> </Wallet>
            </v-col> -->
        
            <v-col cols="12">
                <v-list v-for="item in items" :key="item.id" >
                <v-card flat class="pa-0">
                    <v-row align="center" >
                        <v-col cols="4" class="grey--text-2">
                            <h5 class="font-weight-medium">
                                {{ item.trans_date }}
                            </h5>
                        </v-col>
                        <!-- 88********************************************************** -->

                        <v-col cols="4" class="pl-1 pr-0 align-self-center">
                            <h4 class="font-weight-medium">
                                {{ item.content }}
                            </h4>
                            <h5 class="org_price font-weight-thin grey--text-2">
                                {{ item.description }}
                            </h5>
                        </v-col>

                        <!-- 88********************************************************** -->

                        <v-col
                            cols="4"
                            class="text-right"
                        >
                        <!-- style="text-align: -webkit-center" -->
                            <h4 class="sale_price font-weight-medium ">
                                <span v-if="item.type=='buy'"> - </span>
                                {{ formatPrice(item.amount) }}
                            </h4>
                            <h5 class="org_price font-weight-thin grey--text-2">
                                {{ formatPrice(item.balance) }}
                            </h5>
                        </v-col>
                    </v-row>
                </v-card>
                <v-divider></v-divider>
            </v-list>
            </v-col>

            <v-col>
                <div class="text-center">
                    <v-pagination
                    v-model="currentPage"
                    :length="allPage"
                    @input="setPageData"
                    size="1px"
                    ></v-pagination>
                </div>
            </v-col>

            <v-overlay :value="loading_bar">
                <v-progress-circular
                        indeterminate
                        size="64"
                ></v-progress-circular>
            </v-overlay>
            
        </v-row>
</template>

<script>
import axios from "../../service/axios";

export default {
    name: "Ledger",
    components: {
        // Wallet: () => import("@/components/wallet/Wallet"),
    },

    data() {
        return {
            allData: null, 
            items: [],
            page: 1, 
            allPage: null, 
            currentPage: 1, 
            loading_bar: false,
        };
    }, // data

    beforeDestroy() {
        // this.$store.commit({ type: "setSubPage", sub_page: "main" });
        console.log('Main Vue destroyed')
    },

    computed: {
        // ...mapState('G_NAME', [])
    }, // computed

    async mounted() {
        var _this = this;
        window.scrollTo(0,0)

        window.onpopstate = function () {
            // console.log('History : ', window.history.length)
            // for(var i=0; window.history.length>i; i++) {
            //     window.history.go(-1)
            // }
            _this.$router.replace("/");
        };

        var userData = this.$store.getters.getUserData;
        // console.log("Userdata : ", userData);
        let params = {
            user_id: userData._id,
        };
        // console.log(`User _ID : ${params}`);
        // console.log(`User _ID : ${userData._id}`);
        this.loading_bar = true;
        let data = await axios.get("/bank/getUserRecord", { params: params });
        if(data) this.loading_bar = false;
        // console.log("Get bank data : ", data);
        // let preData = data.data.data.item
        // console.log('Pre item......... : ', preData)
        // function myFunction(item) {
        //     if(item.type == 'buy') item.amount
        // }
        // let newData = preData.forEach(myFunction)
        this.allData = data.data.data.item.reverse();
        this.page = this.allData.length
        this.allPage = Math.ceil(this.page / 10)
        this.items = this.allData.slice(0, 10);
        // console.log("Get bank data : ", data.data.data.item)
    }, // mounted

    methods: {
        formatPrice(value) {
            try {
                return (
                    value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "원"
                );
            } catch {
                return value + "원";
            }
        },

        setPageData(page) {
            console.log(page)
            this.items = this.paginate(this.allData, 10, page)
        },

        paginate(array, page_size, page_number) {
            // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
            return array.slice((page_number - 1) * page_size, page_number * page_size);
        },
    }, // methods
}; // export
</script>

<style scoped>
</style>
